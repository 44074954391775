import React from 'react';
import "./Main.css";

const Main = () => {
  return (
    <div className='main'>
        <h1>HVAC, Electricians Service </h1>
        <p>Our ” COZY HOUSE” campaign offers services in the city of San Jose and nearby cities. We offer repair services for electrical systems and HVAC systems. We are also installing new systems and replacing old systems. We increase the efficiency of your home and make it cozy. Contact us for a consultation and a free estimate . 
          WE WILL MAKE YOUR HOME COZY!</p>
    </div>
  )
}

export default Main