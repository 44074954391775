import React from 'react';
import "./Footer.css";
import "./button.css";
import logo from '../images/256.png';

const Footer = () => {
    return(
        <div className="main-footer" id='contact'>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <div className="container">
          <div className="row">
            <h2 id='contact-css'>Contact</h2>
            {/* Column1 */}
          <div className="col">
            <h4>COZY HOUSE INC</h4>
            <h2 className="list-unstyled">
              <li>408-888-4959</li>
              <li>San Jose, California</li>
              <a href="https://www.facebook.com/people/Cozy-House/100079443349696/" class="fa fa-facebook"></a>
              <a href="https://www.instagram.com/_c_o_z_y_house" class="fa fa-instagram"></a>
              <a href="https://www.yelp.com/biz/cozy-house-san-jose-5" class="fa fa-yelp"></a>
            </h2>
          </div>
            <hr />
            <h5 className="col-sm">
              &copy;{new Date().getFullYear()} Cozy House | All rights reserved
            </h5>
          </div>
        </div>
      </div>
    )
}

export default Footer;