import React, { useState } from "react";
import { FaBars, FaTimes, FaReact } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Link } from "react-scroll";
import logo from '../images/logo128.png';
import "./Navbar.css";

const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  const closeMobileMenu = () => {
    setClick(false);
  };

  return (
    <IconContext.Provider value={{ color: "fff" }}>
      <div className="nav">
        <div className="nav-container">
          <a href="/" className="nav-logo" onClick={closeMobileMenu}>
            <img src={logo} className="nav-icon" alt="logo"/>
            Cozy House
          </a>
          <div className="menu-icon" onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="about" className="nav-links" smooth={true} duration={800} onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="about" className="nav-links" smooth={true} duration={800} onClick={closeMobileMenu}>
                Gallery
              </Link>
            </li>
            <li className="nav-item">
              <Link to="contact" className="nav-links" smooth={true} duration={800} onClick={closeMobileMenu}>Contact</Link>
            </li>
            <li className="nav-item">
              <a href="tel:408-888-4959" className="number-link"  onClick={closeMobileMenu}>CALL 408-888-4959</a>
            </li>
          </ul>
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default Navbar;