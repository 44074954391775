import React from "react";
import './App.css';
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Main from "./components/Main";
import ImageSlider from "./components/ImageSlider";
import { SliderData } from "./components/SliderData";

function App() {
  return (
    <div className="page-container">
        <Navbar/>
      <div className="content-wrap">
        <Main/>
        <ImageSlider slides={SliderData} />
      </div>
    <Footer />
  </div>
  );
}

export default App;
